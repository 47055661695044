/* eslint-disable no-extend-native */
import 'unfetch/polyfill/index'
import 'raf/polyfill'

/**
 * Load additional polyfills here
 **/
function loadPolyfills(): Promise<any> {
  addShims()
  //TODO: any
  const polyfills: any = []

  if (!supportsIntersectionObserver()) {
    // const loadIntersectionObserver = async () => {
    //   try {
    //     let observer = await import('intersection-observer');
    //     if(observer) {
    //       polyfills.push(observer)
    //     }
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }
    // loadIntersectionObserver()
    polyfills.push(
      import(
        /* webpackChunkName: 'intersection-observer' */
        'intersection-observer'
      ),
    )
  }

  if (!supportsSrcSet()) {
    console.warn('Browser does not support SrcSet') // polyfills.push(
    //   import(/* webpackChunkName: 'picturefill' */ './picturefill'),
    // )
  }

  return Promise.all(polyfills)
}

function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in global.IntersectionObserverEntry.prototype
  )
}

function supportsSrcSet() {
  const img = document.createElement('img')

  return 'sizes' in img
}

function addShims() {
  if (!window.crypto) {
    //TODO: Check the line below
    // @ts-ignore
    window.crypto = window.msCrypto
  }

  /**
   * Shim the requestIdleCallback method if needed
   **/
  global.requestIdleCallback =
    global.requestIdleCallback ||
    function(cb) {
      const start = Date.now()

      return setTimeout(function() {
        cb({
          didTimeout: false,
          timeRemaining: function() {
            return Math.max(0, 50 - (Date.now() - start))
          },
        })
      }, 1)
    }

  global.cancelIdleCallback =
    global.cancelIdleCallback ||
    function(id) {
      clearTimeout(id)
    }
}

export default loadPolyfills
