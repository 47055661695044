import sentry from '../utils/error-tracking'
/**
 * Configure third party services
 * Look for the meta tag with the name "site-keys" which should contain the relevant values
 */

export default function configure() {
  const keys = document.querySelector('meta[name="site-keys"]')

  if (keys) {
    const sentryKey = keys.getAttribute('data-sentry')
    if (sentryKey) sentry.init(sentryKey)
  }
}