const modulesMap = new Map()

function addToMap(modules) {
  modules.keys().forEach((name) => {
    // Extract the module name by looking at the dir name
    const moduleName = name.replace(/\.\/(\w+?)\/(index|importer).ts$/, '$1')
    modulesMap.set(moduleName, modules(name))
  })
}

/**
 * Dynamically load all the modules that should be added to the map
 * When rendering serverside we use the static `index.js`, while the client uses the `importer.js` file.
 *
 * Current we look for modules in:
 * * `src/modules/`
 * * `src/campaigns/`
 */
function createModules() {
  if (process.env.SERVER) {
    addToMap(require.context('.', true, /index\.ts$/))
  } else {
    addToMap(require.context('.', true, /importer\.ts$/))
  }
}

export default function getModule(name: string) {
  if (!name) return null

  if (process.env.NODE_ENV === 'test') {
    // Jest doesn't support require.context, so we skip creating modules and require the file directly
    try {
      return require(`./${name}/${process.env.SERVER ? 'index' : 'importer'}.ts`)
    } catch (e) {
      console.warn(e)
    }
  }

  if (!modulesMap.size) createModules()
  return modulesMap.get(name)
}

if (module.hot) {
  // If this module is reloaded, it should dispose the modules maps so it can be refreshed on next render
  module.hot.dispose(() => {
    modulesMap.clear()
  })
}