type Size = {
  width: number
  height: number
}
let monitoring = false
export const currentSize: Size = {
  width: 0,
  height: 0,
}

function limitHeight(height: number): number {
  if (height < 300) return 300
  return height
}

function onResize() {
  // Only update if width changed - Mobile browsers can change the viewport height when scrolling
  if (window.innerWidth !== currentSize.width) {
    if (document.documentElement) {
      // Set a custom property on CSS, that overrides the stylesheet defined default value (100vh), with the current viewport height in px
      document.documentElement.style.setProperty('--vh', `${limitHeight(window.innerHeight)}px`)
    }
  }

  currentSize.width = window.innerWidth
  currentSize.height = window.innerHeight
}

export function monitorViewport() {
  if (monitoring || !global.window) return
  onResize()
  window.addEventListener('resize', onResize)
  monitoring = true
}