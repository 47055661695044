import * as Sentry from '@sentry/browser'

/**
 * Init Raven Sentry tracking
 */
function init(url: string, options?: Record<string, any>) {
  if (url) {
    try {
      Sentry.init({
        //TODO: check if there's a way to get rid of the error below without commenting it out
        // @ts-ignore - BrowserOptions extends Options, so `dns` is a valid prop
        dns: url,
        release: process.env.VERSION,
        ignoreErrors: [
          'Invariant Violation',
          'ResizeObserver loop limit exceeded',
          'document.getElementsByClassName.ToString is not a function',
        ],
        ...options,
      })
    } catch (e) {
      // Failed to install Sentry for some reason - invalid key?
      console.error(e.message)
    }
  }
}

export function fetchError(
  error: Error,
  response: Record<string, any> = {},
  request: Record<string, any> = {},
  extra: Record<string, any> | null | undefined = null,
) {
  if (error && error.message) {
    const { type, status, statusText, url, headers } = response
    const options = {
      tags: {
        fetch: 'fetch',
        page: global.location ? global.location.pathname : '',
      },
      extra: {
        ...extra,
        page: global.location ? global.location.pathname : '',
        url,
        request: { ...request },
        response: {
          type,
          status,
          statusText,
          headers: headers ? Array.from(headers) : undefined,
        },
      },
    }
    //TODO: Check what is the correct type for this
    // @ts-ignore
    Sentry.captureMessage(error.message, options)
  }
}
export function logException(
  ex: Error,
  context: Record<string, any> | null | undefined = null,
) {
  Sentry.captureException(
    ex,
    //TODO: `captureException` expects to take only one argument, so I've commented out the object below!
    // {
    //   extra: context,
    // }
  )
  console.error('Log exception')
}
export function logMessage(
  msg: string,
  context: Record<string, any> | null | undefined = null,
) {
  Sentry.captureMessage(
    msg,
    //TODO: The object below doesn't match the expected type, so I've commented it out!
    // {
    //   extra: context,
    // }
  )
  consoleLog(msg, context)
}

function consoleLog(...args) {
  /* istanbul ignore next if */
  // eslint-disable-next-line no-console
  if (global.window && window.console && console.log) {
    console.log(...args) // eslint-disable-line no-console
  }
}

export default {
  fetchError,
  init,
  logException,
}
