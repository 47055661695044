import PropTypes from 'prop-types'
import * as React from 'react'
import {CSSProperties} from "react";
import serialize from 'serialize-javascript'

const wrapper: CSSProperties = {
  margin: '0 auto',
  maxWidth: 1200,
}
const style: CSSProperties = {
  border: 'solid 2px #ff4935',
  padding: 15,
  margin: '1rem 1.5rem',
}

const header: CSSProperties = {
  margin: 0,
  fontSize: 28,
  fontWeight: 700,
  marginBottom: '0.5rem',
  textTransform: 'none',
}

const reason: CSSProperties = {
  margin: 0,
  fontSize: 20,
  marginBottom: '1rem',
  textTransform: 'none',
}
const summary: CSSProperties = {
  fontWeight: 700,
}

const stackStyle: CSSProperties = {
  background: 'rgba(0, 0, 0, 0.6)',
  color: 'rgb(232, 232, 232)',
  lineHeight: 1.4,
  whiteSpace: 'pre',
  fontFamily: 'Menlo, Consolas, monospace',
  fontSize: 14,
  padding: 15,
  margin: 0,
  overflowX: 'scroll',
}

const serializeProps = (props) =>
  serialize({ ...props, children: undefined }, 2)
    .replace(/\\u002F/g, '/')
    .replace(/\\u003C/g, '<')
    .replace(/\\u003E/g, '>')

type Props = {
  title?: string
  message?: string
  name: string
  outline?: boolean
  debug?: boolean
  details: Array<{
    name: string
    value: any
  }>
  children?: React.ReactNode
}

/**
 * Renders a ErrorMessage to the DOM when a component fails to render.
 */
const FriendlyError = ({ title, message, details, debug, children }: Props) => (
  <div style={wrapper}>
    <div style={style}>
      <h2 style={header}>
        <span>{title}</span>
      </h2>
      <p style={reason}>{message}</p>
      {details &&
        debug &&
        details.map(
          ({ name, value }) =>
            value && (
              <details key={name}>
                <summary style={summary}>{name}</summary>
                <pre style={stackStyle}>
                  <code>{typeof value === 'string' ? value : serializeProps(value)}</code>
                </pre>
              </details>
            ),
        )}
      <div
        style={{
          marginTop: '1.5rem',
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

FriendlyError.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  debug: PropTypes.bool,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
}
FriendlyError.defaultProps = {
  title: 'An error occurred',
  message: 'Try reloading the page.',
}
export default FriendlyError