// It's important that we import 'core-js' before everything else, including React.
// Importing it later will break React in IE11
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'focus-visible'
import './styles/global.css'

import configureThirdParty from './config/thirdparty'
import hydrate from './modules/hydrate'
import loadPolyfills from './utils/shims'
import { monitorViewport } from './utils/viewport-monitor'

const crawlers = /(PhantomJS)\/(\d+)\.(\d+)\.(\d+)|HeadlessChrome/
/**
 * Initialize the client code.
 */

export default function init(): Promise<any> {
  /**
   * Configure the third party tools like Sentry and Analytics
   * Do this now, so we can catch any errors with Sentry
   *
   * Skip tracking from known crawlers.
   * Sentry ignores bots, but PhantomJS and HeadlessChrome is not skipped.
   * Also don't want to track analytics when crawling
   */
  if (!crawlers.test(global.navigator.userAgent)) {
    configureThirdParty()
  }

  if (!process.env.TEST) {
    loadPolyfills()
  }

  monitorViewport()

  return renderModules()
}

function renderModules() {
  return Promise.all(hydrate(getServerModules()))
    .catch((e) => {
      // Error occured while rendering.
      // The hydrate method has logged the error and rejected the promise. Carry on.
    })
    .then(() => modulesReady())
}

function modulesReady() {
  if (process.env.DEV && window.self === window.top && global.document) {
    // On dev, disable the server bundle CSS file once the page is rendered.
    // Otherwise you get duplicate CSS selectors for everything. 😨
    const clearServerCSS = () => {
      const serverCssLink = global.document.querySelector(
        'head > link[href="/static/css/server.render.css"]',
      )
      serverCssLink && serverCssLink.setAttribute('disabled', 'true')
    }

    setTimeout(clearServerCSS, 1000)
  } else if (process.env.NODE_ENV !== 'test' && !crawlers.test(global.navigator.userAgent)) {
    /** Example of triggering a pageview count */
    // We need to call myfonts to register a pageview, but it's not important - Delay until everything else is ready
    // Only activated on prod
    // requestIdleCallback(() => {
    //   const style = document.createElement('link')
    //   style.rel = 'stylesheet'
    //   style.href = 'https://hello.myfonts.net/count/355082'
    //
    //   if (document.head) {
    //     document.head.appendChild(style)
    //   }
    // })
  }
}

/**
 * Look for <script> tags that contain JSON used to hydrate the server modules
 **/
function getServerModules() {
  const modules = document.querySelectorAll('script[data-hydrate]')

  return Array.from(modules).map((el) => JSON.parse(el.innerHTML))
}

if (module.hot) {
  /* Support hot reloading the routes */
  module.hot.accept('./modules/hydrate', () => {
    const newHydrate = require('./modules/hydrate').default

    newHydrate(getServerModules())
  })
}

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  // Init the client now - Moved inside init function to make it testable :)
  if (!window.VERSION) {
    window.VERSION = process.env.VERSION
    init()
  }
}