import type { ComponentType } from 'react'
import React from 'react'
import ReactDOM from 'react-dom'

import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary'
import { logException } from '../utils/error-tracking'
import getModule from './modules-map'

type Module = {
  id: string
  name: string
  props: Record<string, any>
  error?: string
  debug?: boolean
}
/**
 * Call with list of server side rendered modules when the app is ready.
 * Each module should contain the follow values: "name", "props" and "id".
 *
 * @param modules {Array} List of modules to render.
 */

export default function hydrate(modules: Array<Module>) {
  return modules.map(
    ({ name, props, id, error, debug }): Promise<any> => {
      const mod = getModule(name)
      const message = error ? error.match(/: (.+\n)/) : null
      const err = message && message.length > 1 ? new Error(message[1]) : undefined

      if (mod) {
        /* Async import the required modules when they are needed */
        return mod
          .default()
          .then((module) => render(module.default, props, id, err, debug))
          .catch(
            (err) =>
              logException(err, {
                name,
                props,
                id,
                modules,
              }),
            true,
          )
      }

      return Promise.reject(`[hydrate] Module not found: ${name}`)
    },
  )
}
export function render(
  module: ComponentType<any>,
  props: Record<string, any>,
  id: string,
  serverError?: Error,
  debug?: boolean,
) {
  const el = document.getElementById(id)

  if (module && el) {
      const renderedElement = React.createElement(
      ErrorBoundary,
    //TODO: Check how to fix it
    // @ts-ignore - Typescript doesn't recognizes React.createElement(module, props) as children
      {
        module: module.displayName || 'Unknown',
        debug,
        serverError,
      },
      React.createElement(module, props),
    )

    if (el.hasChildNodes()) {
      ReactDOM.hydrate(renderedElement, el)
    } else {
      ReactDOM.render(renderedElement, el)
    }
  } else if (!el) {
    throw new Error(`[hydrate] Could not find element with id: '${id}'`)
  } else {
    throw new Error(`[hydrate] Module not found for element with id: '${id}'`)
  }
}