import * as React from 'react'

import FriendlyError from '../../server/FriendlyError'
import { logException } from '../../utils/error-tracking'

type RenderError = {
  module: string
  debug?: boolean
  error?: Error | null | undefined
  errorInfo?: any
  props?: any
}

type Props = {
  module: string
  children: React.ReactNode
  serverError?: Error
  debug?: boolean
}

type State = {
  hasError?: boolean
  error?: Error | null
  errorInfo?: any | null
}

class ErrorBoundary extends React.Component<Props, State> {
  static displayName = 'ErrorBoundary'
  static defaultProps = {
    module: 'Unknown',
    debug: false,
  }

  static renderError({ module, error, errorInfo, props, debug }: RenderError) {
    return (
      <FriendlyError
        name={module}
        message={debug && error ? error.message : undefined}
        debug={debug}
        details={[
          {
            name: 'Props',
            value: props,
          },
          {
            name: 'Stacktrace',
            value: error ? error.stack : null,
          },
          {
            name: 'Info',
            value: errorInfo,
          },
        ]}
      />
    )
  }

  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  }

  componentDidMount() {
    if (this.props.serverError && !this.state.hasError) {
      /* If the server threw an error, ensure it gets shown  */
      this.prepareServerError(this.props.serverError)
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { error, errorInfo } = this.state

    if (error && prevState.error !== error) {
      logException(error, {
        info: errorInfo,
        module: this.props.module,
        page: global.location ? global.location.pathname : '',
        serverSide: this.props.serverError && !errorInfo,
      })
    } else if (error) {
      this.setState({
        hasError: false,
        error: null,
        errorInfo: null,
      })
    }
  }

  componentDidCatch(error: Error, info: Record<string, any>) {
    this.setState({
      hasError: true,
      error,
      errorInfo: info,
    })
  }

  prepareServerError(serverError: Error) {
    if (serverError) {
      this.setState({
        // Only show server errors in debug mode
        hasError: this.props.debug,
        error: serverError,
      })
    }
  }

  render() {
    if (this.state.hasError) {
      if (!this.props.debug) {
        return null
      }

      if (this.props.serverError && !this.props.debug) {
        // If the error already occurred on the server, and not in debug mode then don't render anything for this component
        return null
      }
      //TODO: any
      const child: any = React.Children.only(this.props.children)

      return ErrorBoundary.renderError({
        error: this.state.error,
        errorInfo: this.state.errorInfo,
        module: this.props.module || 'Unknown',
        debug: this.props.debug,
        props: child ? child.props : {},
      })
    }

    return this.props.children
  }
}

export default ErrorBoundary
